

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import LayoutModule from '~/store/layout';

@Component<FooterSection>({
    layout: 'default',
})
export default class FooterSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    get footerType() {
        return this.layout.configuration.footerType;
    }

    set footerType(footerType) {
        this.layout.setConfiguration({footerType});
    }

    get showFooterLogo() {
        return this.layout.configuration.showFooterLogo;
    }

    set showFooterLogo(showFooterLogo) {
        this.layout.setConfiguration({showFooterLogo});
    }

    get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }

    set footerInfoLinkLocation(footerInfoLinkLocation) {
        this.layout.setConfiguration({footerInfoLinkLocation});
    }
}
