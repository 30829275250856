/* eslint-disable @typescript-eslint/no-explicit-any no-useless-escape */
const settingFiles = require.context('~/settings/', true, /(\.json|\.ya?ml|\.env)/);
function reducer(acc, key) {
    for (const [k, value] of Object.entries(settingFiles(key))) {
        Object.defineProperty(acc, k, { value, enumerable: true, configurable: false });
    }
    return acc;
}
const settings = settingFiles.keys().reduce(reducer, {});

// eslint-disable-next-line no-useless-escape
const regex = /^[\/]+/;

export default (context, inject) => {
    Object.assign(context.env, settings, {
        url(u) {
            return `#{BasePath}#/${u.replace(regex, '')}`;
        },
    });
    inject('settings', context.env);
};
