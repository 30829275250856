import Vue from 'vue';
import _ from 'lodash';
import { ISearchFields } from '~/types/componentTypes';

export class CommonTools {
    static titleCase(str: string) {
        return str
            .toLowerCase()
            .split(' ')
            .map(function(word) {
                return (
                    word
                        .toLocaleLowerCase()
                        .charAt(0)
                        .toLocaleUpperCase() + word.slice(1)
                );
            })
            .join(' ');
    }

    static sentenceCase(str: string) {
        const sentence = str
            .toLowerCase()
            .split(' ')
            .map(function(word) {
                return word.toLocaleLowerCase() + word.slice(1);
            })
            .join(' ');

        return sentence.charAt(0).toLocaleUpperCase() + sentence.slice(1);
    }

    static titleFormat(str: string) {
        return ((str.charAt(0).toLocaleUpperCase() + str.slice(1)).match(/[A-Z][a-z]+/g) || []).join(' ');
    }

    // Add to any component with a vuelidate validator and this will log any validation errors
    // to the console.  This is used to diagnose situations where validation fails silently
    static checkError(validator: any) {
        const errorKeys = Object.keys(validator).filter(key => !key.startsWith('$') && validator[key].$anyError);
        errorKeys.length > 0 && console.error('Error on properties:', errorKeys.join(', '));
    }
}
