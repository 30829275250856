


















import Vue, { RenderContext, CreateElement } from 'vue';
import { multiErrorExtractorMixin } from 'vuelidate-error-extractor';
import Component from 'vue-class-component';

@Component({})
export default class FormSummary extends Vue.extend(multiErrorExtractorMixin) {}
