





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Title } from '~/decorators/Title';

@Component<DefaultError>({
    head() {
        return {
            title: `${this.statusCode} ${this.title}`,
        };
    },
})
export default class DefaultError extends Vue {
    @Prop({ type: [Number, String], required: false })
    public code!: string;

    @Prop({ type: Object, required: false })
    public details!: { icon: [string, string]; title: string; message: string; statusCode: number };

    public get statusCode() {
        return this.code || this.errorPage.statusCode;
    }

    public get errorPage() {
        return this.details || this.$settings.errorPages[this.code] || this.$settings.errorPages.default;
    }

    public get title() {
        return this.errorPage.title;
    }

    public get icon() {
        return this.errorPage.icon;
    }

    public get message() {
        return this.errorPage.message;
    }
}
