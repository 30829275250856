























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component<StaticFooter>({})
export default class StaticFooter extends Vue {
    @Prop()
    public brkPoints!: object;
}
