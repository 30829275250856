

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';

@Component<ScrollingFooter>({})
export default class ScrollingFooter extends Vue {
    @Resolve
    public layout!: LayoutModule;
    mounted() {
        this.layout.replaceProgramName(this.layout.configuration.programName, this.layout.previousProgramName)
    }

    @Watch('layout.configuration.programName', {deep: true})
    public watchProgramName(programName: string) {
        this.layout.replaceProgramName(programName, this.layout.previousProgramName)
    }
}
