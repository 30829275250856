






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ImageFileInput from '~/components/configuration/ImageFileInput.vue';

@Component<LogoSection>({
    layout: 'default',
    components: {
        ImageFileInput,
    }
})
export default class LogoSection extends Vue {

    @Prop()
    public icon: any;
}
