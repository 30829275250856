import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import moment from 'moment';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    EnrollmentInsVerRequestModel,
    EnrollmentDemographicsModel,
    EnrollmentInsuranceVerificationModel,
} from '@trialcard/apigateway.models';
import { Gender, RoleType } from '@trialcard/enums';

import { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeCouponApi} from '@trialcard/apigateway.client';
import { Settings } from '~/services/settings';

import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates'
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers'
import EligibilityModule from './eligibility';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollCoupon!: EdgeCouponApi

    @Resolve
    public eligibility! : EligibilityModule

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    defaultPatientInformationData = {
            patientId: '',
            address1: '',
            address2: '',
            city: '',
            dateOfBirth: '',
            email: '',
            firstName: '',
            gender: '',
            lastName: '',
            phone: '',
            state: '',
            postalCode: ''
    };


    defaultInsuranceInformationData = {
        firstNameInsurance:'',
        groupNumber:'',
        binInsurance:'',
        pcnInsurance:''
    } ;

//defaultPrescriberInformationData
defaultPrescriberInformationData = {
        pifirstName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],

    };

//defaultSpecialPharmacyInformationData
defaultSpecialPharmacyInformationData = {
    specialPharmacyname: '' as string,
    addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],

    };

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                accountRelationshipIsPrimary: true,
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];

    defaultSiteData = [
        {
            model: {
                accountRelationshipIsPrimary: true,
                siteRoleType: RoleType.PrescriberSite,
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        addressOne: '',
                        addressTwo: '',
                        zip: '',
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultPayorData = [
        {
            model: {
                payorId: (null as unknown) as number,
                payorType: (null as unknown) as number,
                accountRelationshipIsPrimary: true,
                payorGroup: '',
                payorName: '',
                webAddresses: [],
                addresses: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [],
                contacts: [],
                payorBenefit: {
                    dateOfBirth: null as Moment | null,
                    policyHolderFirstName: '',
                    policyHolderLastName: '',
                    relationshipToPatient: undefined,
                    policyNumber: '',
                    planType: undefined,
                },
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[];

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    defaultMemberNumber = pharmacyMN.number

    defaultExpirationDate = rolling365

    memberNumber = this.defaultMemberNumber;
    expirationDate = this.defaultExpirationDate;
    patientInformationData = { ...this.defaultPatientInformationData };
    prescriberInformationData = { ...this.defaultPrescriberInformationData };
    insuranceInformationData = { ...this.defaultInsuranceInformationData };
    specialPharmacyInformationData = { ...this.defaultSpecialPharmacyInformationData };
    prescriberData = [ ...this.defaultPrescriberData ];
    siteData = [ ...this.defaultSiteData ];
    payorData = [ ...this.defaultPayorData ];
    contactPreferences = [...this.defaultContactPreferences];
    serveyData = [] as any;

    @Mutation
    public async updatePatientInformation(newPatientInformation: EnrollmentDemographicsModel) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    //updatePrescriberInformation
    @Mutation
    public async updateInsuranceInformation(newInsuranceInformation: typeof EnrollmentModule.prototype.insuranceInformationData) {
        this.insuranceInformationData = { ...this.insuranceInformationData, ...newInsuranceInformation };
    }

//updatePrescriberInformation
    @Mutation
    public async updatePrescriberInformation(newPatientInformation: typeof EnrollmentModule.prototype.prescriberInformationData) {
        this.prescriberInformationData = { ...this.prescriberInformationData, ...newPatientInformation };
    }

   //updateSpecialPharmacyInformation
   @Mutation
   public async updateSpecialPharmacyInformation(newPatientInformation: typeof EnrollmentModule.prototype.specialPharmacyInformationData) {
       this.specialPharmacyInformationData = { ...this.specialPharmacyInformationData, ...newPatientInformation };
   }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }

    @Mutation
    public async updatePayors(newPayorData: UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[]) {
        this.payorData = [...newPayorData];
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: Moment) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public async updateServey(newServeyData: any) {
        this.serveyData = [...newServeyData];
    }
    @Action({ rawError: true })
    public clearStore() {
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updatePrescribers(this.defaultPrescriberData);
        this.updateSites(this.defaultSiteData);
        this.updatePayors(this.defaultPayorData);
        this.updateContactPreferences(this.defaultContactPreferences);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        try {
            const enrollPatientModel : EnrollmentInsVerRequestModel = {
                insuranceVerification : {
                    payerName: this.insuranceInformationData.firstNameInsurance,
                    bin: this.insuranceInformationData.binInsurance,
                    pcn: this.insuranceInformationData.pcnInsurance,
                    payerPhone: '0',
                    payerMedicalId: ''
                },
                survey: [
                        ...this.eligibility.eligibilityData
                ],
                demographics: {
                    ...this.patientInformationData,
                    dateOfBirth: this.patientInformationData.dateOfBirth ? moment(this.patientInformationData.dateOfBirth).format('L') : '',
                }
            } as EnrollmentInsVerRequestModel;
            response = await this.enrollCoupon.enrollmentEnrollmentWithInsurance(
                Number(this.settings.programId),
                enrollPatientModel
            );
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }


}
