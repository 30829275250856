export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    program: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    footerDrugLogo: string;
    footerPharmaLogo: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyId1 : string;
    surveyId2 : string,
    isFederalQuestionId: string;
    isFederalAnswerId: string;
    patientDiagnosisQuestionId: string;
    patientDiagnosisC210AnswerId: string;
    patientDiagnosisC211AnswerId: string;
    patientDiagnosisC212AnswerId: string;
    patientDiagnosisC218AnswerId: string;
    patientDiagnosisOtherAnswerId: string;
    doesAgreeMarketingQuestionId: string;
    doesAgreeMarketingAnswerId: string;
    enrollmentTypeQuestion:string;
    enrollmentTypeHUB: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    eligibilityQuestionId1: string;
    eligibilityAnswerId1Yes: string;
    eligibilityAnswerId1No: string;
    eligibilityQuestionId2: string;
    eligibilityAnswerId21: string;
    eligibilityAnswerId22: string;
    eligibilityAnswerId23: string;
    eligibilityAnswerId24: string;
    eligibilityQuestionId3: string;
    eligibilityAnswerId3Yes: string;
    eligibilityAnswerId3No: string;
    eligibilityQuestionId4: string;
    eligibilityAnswerId4Yes: string;
    eligibilityAnswerId4No: string;
    eligibilityQuestionId5: string;
    eligibilityAnswerId5Yes: string;
    eligibilityAnswerId5No: string;
    eligibilityQuestionId6: string;
    eligibilityAnswerId61: string;
    eligibilityAnswerId62: string;
    eligibilityAnswerId63: string;
    eligibilityQuestionId7: string;
    eligibilityAnswerId7Yes: string;
    eligibilityAnswerId27No: string;

    eligibilityQuestionId8: string;
    eligibilityAnswerId8Yes: string;
    eligibilityAnswerId28No: string;

    eligibilityQuestionId9: string;
    eligibilityAnswerId9Yes: string;
    eligibilityAnswerId9No: string;

    eligibilityQuestionId10: string;
    eligibilityAnswerId10Yes: string;
    eligibilityAnswerId10No: string;

    eligibilityQuestionId11: string;
    eligibilityAnswerId11Yes: string;
    eligibilityAnswerId11No: string;

    eligibilityQuestionId12: string;
    eligibilityAnswerId12Yes: string;
    eligibilityAnswerId12No: string;

    eligibilityQuestionId13: string;
    eligibilityAnswerId13Yes: string;
    eligibilityAnswerId13No: string;

    groupNumber: string;

    patientInformationQuestionId1: string;
    patientInformationQuestionId2: string;
    patientInformationQuestionId3: string;
    patientInformationQuestionId4: string;
    patientInformationQuestionId5: string;
    patientInformationQuestionId6: string;
    patientInformationQuestionId7: string;
    patientInformationQuestionId8: string;
    patientInformationQuestionId9: string;
    patientInformationQuestionId10: string;
    patientInformationQuestionId11: string;
    patientInformationQuestionId12: string;
}
