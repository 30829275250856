import colors from 'vuetify/es5/util/colors';
import { Theme, VuetifyThemeVariant } from 'vuetify/types/services/theme';

// Any color names added to theme will need to be added to this interface
interface IExtendedTheme extends VuetifyThemeVariant {
    charcoal: string;
    peach: string;
    yellow: string;
}
export default {
    dark: false,
    themes: {
        light: {
            primary: '#005cab', // blue
            secondary: '#f47920', // orange
            accent: '#6cb33f', // green
            error: colors.red.lighten2,
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
            charcoal: '#444444',
            peach: '#fbb884',
            yellow: '#f2ee82',
        } as Partial<IExtendedTheme>,
        dark: {
            primary: '#005cab', // blue
            secondary: '#f47920', // orange
            accent: '#6cb33f', // green
            error: colors.red.darken2,
            warning: colors.amber.darken2,
            info: colors.lightBlue.darken2,
            success: colors.green.darken2,
            charcoal: '#444444',
            peach: '#fbb884',
            yellow: '#f2ee82',
        } as Partial<IExtendedTheme>,
    },
    options: {
        // inject color vars into html root element
        customProperties: true,
    },
} as Theme;
