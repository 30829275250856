


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import LayoutModule from '~/store/layout';

@Component<LayoutSection>({
    layout: 'default',
})
export default class LayoutSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    get isProgressTracker() {
        return this.layout.configuration.isProgressTracker;
    }

    set isProgressTracker(isProgressTracker) {
        this.layout.setConfiguration({isProgressTracker});
    }

    get landingPageAlignment() {
        return this.layout.configuration.landingPageAlignment;
    }

    set landingPageAlignment(landingPageAlignment) {
        this.layout.setConfiguration({landingPageAlignment});
    }
}
