






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import LayoutModule from '~/store/layout';

@Component<FeatureSection>({
    layout: 'default',
})
export default class FeatureSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    get isHaveACard() {
        return this.layout.configuration.isHaveACard;
    }

    set isHaveACard(isHaveACard) {
        this.layout.setConfiguration({isHaveACard});
    }

    get isMarketingOptIn() {
        return this.layout.configuration.isMarketingOptIn;
    }

    set isMarketingOptIn(isMarketingOptIn) {
        this.layout.setConfiguration({isMarketingOptIn});
    }

    get hasConditionalFields() {
        return this.layout.configuration.hasConditionalFields;
    }

    set hasConditionalFields(hasConditionalFields) {
        this.layout.setConfiguration({hasConditionalFields});
    }

    get hasDatePicker() {
        return this.layout.configuration.hasDatePicker;
    }

    set hasDatePicker(hasDatePicker) {
        this.layout.setConfiguration({hasDatePicker});
    }
}
