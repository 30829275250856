
















































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import TermsAndConditions from '~/components/TermsAndConditions.vue';
import Isi from '~/components/Isi.vue';

@Component<Footer>({
    components: {
        TermsAndConditions,
        Isi,
    },
})
export default class Footer extends Vue {
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    public dialog = false;
    scrollToTerms() {
        const element = document.querySelector("#patient-terms-and-conditions")
        if (element) {
            element.scrollIntoView({
                block: "nearest",
                inline: "center",
                behavior: "smooth",
            });
            return;
        }
    }
}
