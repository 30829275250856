























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Title } from '~/decorators/Title';
import ColorPicker from '~/components/configuration/ColorPicker.vue'

@Component<BackgroundSection>({
    layout: 'default',
    components: {
        ColorPicker,
    },
})
export default class BackgroundSection extends Vue {
    @Prop()
    public icon: any;
}
