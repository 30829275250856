







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import { maxLength } from 'vuelidate/lib/validators';
import LayoutModule from '~/store/layout';
import { Validate } from '~/validation/Validate';

@Component<TextSection>({
    layout: 'default',
})
export default class TextSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon!: any;

    @Validate({maxLength: maxLength(25)})
    drugName = '';

    handleDrugName() {
        this.layout.setConfiguration({ programName: this.drugName})
        this.drugName = '';
    }
}
