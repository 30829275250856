






































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { numeric, minLength } from 'vuelidate/lib/validators';
import { Prop } from 'vue-property-decorator';
import LayoutModule from '~/store/layout';

@Component<HeaderSection>({
    layout: 'default',
    validations: {
        bottomBarHeight: {
            numeric,
            minLength: minLength(0),
        },
    },
})
export default class HeaderSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    get bottomBarHeight() {
        return this.layout.configuration.bottomBarHeight;
    }

    set bottomBarHeight(bottomBarHeight) {
        this.layout.setConfiguration({bottomBarHeight});
    }

    get logoPosition() {
        return this.layout.configuration.logoPosition;
    }

    set logoPosition(logoPosition) {
        this.layout.setConfiguration({logoPosition});
    }

    get headerStyle() {
        return this.layout.configuration.headerStyle;
    }

    set headerStyle(headerStyle) {
        this.layout.setConfiguration({headerStyle});
    }

    get hasHeaderBottomBar() {
        return this.layout.configuration.hasHeaderBottomBar;
    }

    set hasHeaderBottomBar(hasHeaderBottomBar) {
        this.layout.setConfiguration({hasHeaderBottomBar});
    }
}
