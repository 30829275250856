import { Action, Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { SurveySessionValueModel , EnrollmentSurveyQuestionModel} from '@trialcard/apigateway.models';
import { Resolve } from 'vue-di';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { Settings } from '~/services/settings';
export interface IEligibilityData {
    eligibilityFederal?: string;
    diagnosis?: string;
    doesAgreeMarketing?: string;
}

export interface SaveQuestionModel {
        activeQuestion : string,
        showQuestion18 : boolean,
        showQuestion12 : boolean
}
@InjectModule({ stateFactory: true }, module)
export default class EligibilityModule extends InjectVuexModule {
    public eligibilityData = [] as EnrollmentSurveyQuestionModel[];

    public activeQuestionId = {
        activeQuestion : "",
        showQuestion12 : false,
        showQuestion18 : false
    } as SaveQuestionModel;


    @Mutation
    public async updateEligibilityData(newEligibilityData: EnrollmentSurveyQuestionModel[]) {
        this.eligibilityData = newEligibilityData;
    }

    @Mutation
    public async updateActiveQuestion(value: SaveQuestionModel) {
        this.activeQuestionId.activeQuestion =  value.activeQuestion;
        this.activeQuestionId.showQuestion12 = value.showQuestion12;
        this.activeQuestionId.showQuestion18 = value.showQuestion18;
    }


    @Resolve
    public settings!: Settings;


    @Action({ rawError: true })
    public  checkEligibility(): boolean {
        const correctAnswers = [
            0,
            +this.settings.eligibilityAnswerId1Yes,
            +this.settings.eligibilityAnswerId21,
            +this.settings.eligibilityAnswerId22,
            +this.settings.eligibilityAnswerId23,
            +this.settings.eligibilityAnswerId24,
            +this.settings.eligibilityAnswerId3Yes,
            +this.settings.eligibilityAnswerId4Yes,
            +this.settings.eligibilityAnswerId5Yes,
            +this.settings.eligibilityAnswerId61,
            +this.settings.eligibilityAnswerId62,
            +this.settings.eligibilityAnswerId63,
            +this.settings.eligibilityAnswerId7Yes,
            +this.settings.eligibilityAnswerId8Yes,
            +this.settings.eligibilityAnswerId9No,
            +this.settings.eligibilityAnswerId10Yes,
            +this.settings.eligibilityAnswerId11Yes,
            +this.settings.eligibilityAnswerId12Yes,
            +this.settings.eligibilityAnswerId13Yes,
        ];
        const isEligible = this.eligibilityData.every(question => correctAnswers.includes(Number(question.answerKey)));
        return isEligible;
    }
}
