





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import LayoutModule from '~/store/layout';

@Component<ButtonSection>({
    layout: 'default',
})
export default class ButtonSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    get isOutlined() {
        return this.layout.configuration.isOutlined;
    }

    set isOutlined(isOutlined) {
        this.layout.setConfiguration({isOutlined});
    }

    get isRounded() {
        return this.layout.configuration.isRounded;
    }

    set isRounded(isRounded) {
        this.layout.setConfiguration({isRounded});
    }
}
