import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _57c76dd5 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _a650c654 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _0f06c947 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _43f808b4 = () => interopDefault(import('..\\pages\\enroll\\eligibility.vue' /* webpackChunkName: "pages_enroll_eligibility" */))
const _1c9ef50b = () => interopDefault(import('..\\pages\\enroll\\patient-information.vue' /* webpackChunkName: "pages_enroll_patient-information" */))
const _502a99aa = () => interopDefault(import('..\\pages\\enroll\\success.vue' /* webpackChunkName: "pages_enroll_success" */))
const _09be30b6 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _09cc4837 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _09e87739 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _09f68eba = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _3ea46bf7 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _14014f9f = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _57c76dd5,
    name: "error"
  }, {
    path: "/rejection",
    component: _a650c654,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _0f06c947,
    name: "unlock"
  }, {
    path: "/enroll/eligibility",
    component: _43f808b4,
    name: "enroll-eligibility"
  }, {
    path: "/enroll/patient-information",
    component: _1c9ef50b,
    name: "enroll-patient-information"
  }, {
    path: "/enroll/success",
    component: _502a99aa,
    name: "enroll-success"
  }, {
    path: "/errors/400",
    component: _09be30b6,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _09cc4837,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _09e87739,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _09f68eba,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _3ea46bf7,
    name: "errors-500"
  }, {
    path: "/",
    component: _14014f9f,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
